// v1
const v = 2
console.log('v', v)
// import * as Sentry from '@sentry/browser'
// import { BrowserTracing } from '@sentry/tracing'

if (!globalThis.URLPattern) {
  await import('urlpattern-polyfill')
}

// Sentry.init({
//   dsn: 'https://70dc20c64d73454ba14b4933daf6fef4@o332068.ingest.sentry.io/1855853',
//   integrations: [new BrowserTracing()],

//   // Set tracesSampleRate to 1.0 to capture 100%
//   // of transactions for performance monitoring.
//   // We recommend adjusting this value in production
//   tracesSampleRate: 1.0,
// })

import Router from './lib/router.js'
import { initAccountThroughSockets } from './my-peer-space-2/app'

initAccountThroughSockets()

const router = new Router()

router.on('/', homePage)
router.on('/browse/', homePage)
router.on('/mrsprint', () => import('./mrsprint/script.js'))
router.on('/fun-grid', () => import('./fun-grid/app.jsx'))
router.on('/canvas-colorful-noise', () =>
  import('./canvas-colorful-noise/script.ts')
)

router.on('/share-single-picture', () =>
  import('./share-single-picture/script.js')
)

router.on('/canvas-basic-signs', () => import('./canvas-basic-signs/script.ts'))
router.on('/blank', () => import('./blank/app.js'))
router.on('/scroll-swipe-fever', () => import('./scroll-swipe-fever/app.js'))
router.on('/releasier', () => import('./releasier/app.jsx'))
router.on('/preact-fun', () => import('./preact-fun/app.jsx'))
router.on('/posts/do-fullscreen-elements-still-event-bubble', () =>
  import('./posts/do-fullscreen-elements-still-event-bubble/app.jsx')
)
router.on('/mirror', () => import('./mirror/app.ts'))
router.on('/location-pin-point', () => import('./location-pin-point/app.ts'))
router.on('/my-peer-space-2', () => import('./my-peer-space-2/app.js'))
router.on('/my-peer-space-2/me', () => import('./my-peer-space-2/me/app.js'))
router.on('/my-peer-space', () => import('./my-peer-space/app.js'))
router.on('/interruptless', () => import('./interruptless/app.js'))
router.on('/stop-letter-picker', () => import('./stop-letter-picker/app.jsx'))
router.on('/web-speech-to-text', () => import('./web-speech-to-text/app.ts'))
router.on('/blank', () => import('./blank/app.ts'))
router.on('/quick-audio-recording', () =>
  import('./quick-audio-recording/app.ts')
)
router.on('/server-speech-to-text', () =>
  import('./server-speech-to-text/app.ts')
)

router.match('/posts/*', () => import('./posts/app.ts'))

// I tried to dynamically load the script for the current page, but it didn't work 4 years ago.
// router.on(location.pathname, async () => await import(`.${location.pathname.substr(0, location.pathname.length - 1)}/script.js`))

// PAGE FADE IN
// window.addEventListener('pageshow', (event) => {
//   console.log('pageshow')

//   if (event.persisted) {
//   } else {
//     if (document.body.style.opacity !== '0') {
//       return
//     }

//     document.body
//       .animate([{ opacity: 0 }, { opacity: 1 }], {
//         duration: 300,
//         fill: 'forwards',
//       })
//       .finished.then(() => {
//         console.log('finished')
//       })
//   }
// })

async function homePage() {
  const ce = await import('./lib/custom-element-single-letter')
  ce.defineElement()
}

// PAGE FADE OUT
// document.addEventListener(
//   'click',
//   (event) => {
//     console.dir(event)
//     if (event.ctrlKey) {
//     } else if (event.metaKey) {
//     } else if (event.target.tagName === 'A') {
//       event.preventDefault()

//       document.body
//         .animate([{ opacity: 1 }, { opacity: 0 }], {
//           duration: 300,
//           fill: 'forwards',
//         })
//         .finished.then((e, i) => {
//           console.log('finished', e, i)
//           location.href = event.target.href
//         })
//     }
//   },
//   true
// )
